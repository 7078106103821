import {
  Button,
  Grid,
  makeStyles,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from "@material-ui/core"
import React from "react"
import CoreValues from "../components/CoreValues"
import Layout from "../components/Layout"
import ContentPreview from "../components/ContentPreview"
import trainingPrograms from "../components/constants/TrainingPrograms"
import { graphql, Link } from "gatsby"
import customTheme from "../gatsby-theme-material-ui-top-layout/customTheme"

export const query = graphql`
  {
    allFile(
      filter: {
        relativePath: { in: ["course-1.jpg", "course-2.jpg", "course-3.jpg"] }
      }
    ) {
      nodes {
        courseImage: childrenImageSharp {
          gatsbyImageData(width: 400, height: 250, placeholder: BLURRED)
        }
      }
    }
  }
`

const useStyles = makeStyles(theme => ({
  sectionSeperator: {
    marginBottom: "8rem",
  },
  sectionSeperatorShadowed: {
    borderRadius: "100%",
    margin: "6rem auto 8rem",
    boxShadow: "0 14px 0px -6px #660000",
    width: "40%",
    height: "1.4rem",
  },
  pageTitle: {
    fontSize: "3.2rem",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "8rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.4rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.6rem",
    },
  },
  sectionTitle: {
    fontSize: "3.2rem",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.4rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.6rem",
    },
  },
  programTitle: {
    fontSize: "2.4rem",
    fontWeight: "500",
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.6rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.4rem",
    },
  },
  littleRedSquare: {
    backgroundColor: "rgba(195, 0, 0, 1)",
    width: "1.5rem",
    height: "1.5rem",
    display: "inline-block",
    marginLeft: "1rem",
    borderRadius: "3px",
    [theme.breakpoints.down("xs")]: {
      width: "1rem",
      height: "1rem",
    },
  },
  button: {
    fontSize: "1.8rem",
    marginTop: "2rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.6rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
  ctaText: {
    fontSize: "1.8rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.6rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
}))

const TrainingCourses = ({ data }) => {
  const classes = useStyles()
  // const matches600 = useMediaQuery("(max-width: 600px)")
  const matches960 = useMediaQuery("(max-width: 960px)")

  const pageMeta = {
    title: "الدورات التدريبية",
    description: "دورات في أمن وحماية المعلومات",
    keywords: [
      "أمن المعلومات",
      "حماية أمنية",
      "دورات أمنية",
      "تدريب",
      "شهادات أمنية معتمدة",
      "مقدمة في أمن المعلومات",
      "آيزو",
      "iso",
      "pcidss",
    ],
  }

  return (
    <Layout pageMeta={pageMeta} isHero>
      <ThemeProvider theme={customTheme}>
        <Grid container direction="column">
          <Grid item className={classes.sectionSeperator}>
            <CoreValues />
          </Grid>
          <Grid item align="center">
            <Typography className={classes.pageTitle}>
              الدورات والبرامج التدريبية لتطوير الأمن المعلوماتي لمؤسستك
            </Typography>
          </Grid>

          {/* Courses */}
          <Grid item container direction="column">
            <Grid item align={matches960 ? "center" : "right"}>
              <Typography className={classes.sectionTitle} paragraph>
                دوراتنا التدريبية:
              </Typography>
            </Grid>
            <Grid item>
              <ContentPreview
                direction="row"
                isButton
                content="courses"
                data={data}
              />
            </Grid>
            <Grid item className={classes.sectionSeperatorShadowed} />
          </Grid>

          {/* Trainings */}
          <Grid item container direction="column">
            <Grid item>
              <Typography
                className={classes.sectionTitle}
                paragraph
                style={{ marginBottom: "2rem" }}
                align="right"
              >
                برامجنا التدريبية:
              </Typography>
            </Grid>
            <Grid item container direction="column" className="rtl">
              {trainingPrograms.map(program => {
                return (
                  <Grid item>
                    <Typography className={classes.programTitle} align="right">
                      <div className={classes.littleRedSquare} />
                      {program.title}
                    </Typography>
                  </Grid>
                )
              })}
            </Grid>
            <Grid item className={classes.sectionSeperatorShadowed} />
          </Grid>

          {/* Call to action */}
          <Grid
            item
            container
            direction="column"
            justify="center"
            align="center"
            spacing={2}
          >
            <Grid item>
              <Typography
                className={classes.sectionTitle}
                style={{ marginBottom: "2rem" }}
              >
                كيف يمكننا تأمينك؟
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.ctaText} paragraph>
                نعمل على تأمين بياناتك بمجموعة من الاجراءات والعمليات الاستباقية
                لفهم المخاطر والتهديدات وتوفير التدريب والمعرفة اللازمة وتجهيز
                فرق قادرة على مراقبة واصطياد التهديدات بخبرة واسعة تفوق 25 عاما
                وتجارب متعددة في التعامل مع التهديدات الأمنية
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                component={Link}
                to="/contact"
                className={classes.button}
              >
                تواصل معنا
              </Button>
            </Grid>
            <Grid item className={classes.sectionSeperator} />
          </Grid>
        </Grid>
      </ThemeProvider>
    </Layout>
  )
}

export default TrainingCourses
