const trainingPrograms = [
  { title: "مقدمة نظام إدارة أمن المعلومات أيزو 27001:2013" },
  { title: "أساسيات نظام إدارة امن المعلومات أيزو 27001:2013" },
  { title: "كبير منفذي نظام إدارة امن المعلومات أيزو 27001:2013" },
  { title: "كبير مدققي نظام إدارة امن المعلومات" },
  { title: "كبير منفذي نظام إدارة استمرارية الاعمال" },
  { title: "كبير مديري ادراة المخاطر ايزو 31000" },
  { title: "نظام حماية معلومات حاملي البطاقات الائتمانية" },
  { title: "التوعية الامنية" },
]

export default trainingPrograms
