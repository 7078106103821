import React from "react"
import {
  Security,
  VerifiedUser,
  Policy,
  Autorenew,
  Poll,
  Timer,
  NewReleases,
} from "@material-ui/icons"
import { Grid, makeStyles, Typography, useMediaQuery } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  valueContainer: {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: "100%",
    width: "8rem",
    height: "8rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "all .2s",
    boxShadow: "0 8px 6px -6px #000",
    "&:hover": {
      transform: "scale(.95)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "5rem",
      height: "5rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "3rem",
      height: "3rem",
    },
  },
  valueIcon: {
    width: "3rem",
    height: "3rem",
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      width: "3rem",
    },
    [theme.breakpoints.down("xs")]: {
      width: "1.5rem",
    },
  },
  valueName: {
    fontSize: "1.6rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: ".8rem",
    },
  },
}))

const CoreValues = () => {
  const classes = useStyles()
  const matches600 = useMediaQuery("(max-width: 600px)")

  return (
    <Grid container justify="center" spacing={matches600 ? 2 : 4}>
      {/* Monitoring */}
      <Grid item>
        <Grid container direction="column" alignItems="center" spacing={1}>
          <Grid item className={classes.valueContainer}>
            <Policy className={classes.valueIcon} />
          </Grid>
          <Grid item>
            <Typography className={classes.valueName}>مراقبة</Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* Responding */}
      <Grid item>
        <Grid container direction="column" alignItems="center" spacing={1}>
          <Grid item className={classes.valueContainer}>
            <VerifiedUser className={classes.valueIcon} />
          </Grid>
          <Grid item>
            <Typography className={classes.valueName}>استجابة</Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* Protecting */}
      <Grid item>
        <Grid container direction="column" alignItems="center" spacing={1}>
          <Grid item className={classes.valueContainer}>
            <Security className={classes.valueIcon} />
          </Grid>
          <Grid item>
            <Typography className={classes.valueName}>حماية</Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* Commitment */}
      <Grid item>
        <Grid container direction="column" alignItems="center" spacing={1}>
          <Grid item className={classes.valueContainer}>
            <Timer className={classes.valueIcon} />
          </Grid>
          <Grid item>
            <Typography className={classes.valueName}>التزام</Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* Integrity */}
      <Grid item>
        <Grid container direction="column" alignItems="center" spacing={1}>
          <Grid item className={classes.valueContainer}>
            <Poll className={classes.valueIcon} />
          </Grid>
          <Grid item>
            <Typography className={classes.valueName}>نزاهة</Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* Opennes */}
      <Grid item>
        <Grid container direction="column" alignItems="center" spacing={1}>
          <Grid item className={classes.valueContainer}>
            <NewReleases className={classes.valueIcon} />
          </Grid>
          <Grid item>
            <Typography className={classes.valueName}>انفتاح</Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* Developing */}
      <Grid item>
        <Grid container direction="column" alignItems="center" spacing={1}>
          <Grid item className={classes.valueContainer}>
            <Autorenew className={classes.valueIcon} />
          </Grid>
          <Grid item>
            <Typography className={classes.valueName}>تحسين</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CoreValues
